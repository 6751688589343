<template lang="pug">
div.pb-24
	template(v-if="isVisibleData")
		Skeleton(v-if="isLoading")

		template(v-else)
			h2.mb-24.tab-title Количество карточек, которые не попадают в топ-50

			ul.flex.card-list(v-if="resp")
				li.card-item
					ContainerForData.card(
						width="100%"
						:isLoading="isLoading"
					)
						template(#header-left)
							span.card-title Online Stores
						template(#data)
							.content
								table
									tbody
										tr(v-for="store in resp.productNotRankedInTop50ByOnlineStore" :key="store.storeId")
											td {{ store.storeName }}
											td
												.py-12.flex.items-center
													ProgressBarGraphic.mr-auto(
														:progress-percent="getPercent(store.value, maxValueStores)"
														:width="progressBarWidth"
														theme="transparent"
													)
													| {{ store.value }}

				li.card-item
					ContainerForData.card(
						width="100%"
						:isLoading="isLoading"
					)
						template(#header-left)
							span.card-title Categories
						template(#data)
							.content
								table
									tbody
										tr(v-for="category in resp.productNotRankedInTop50ByCategory" :key="category.filterId")
											td {{ category.filterName }}
											td
												.py-12.flex.items-center
													ProgressBarGraphic.mr-auto(
														:progress-percent="getPercent(category.value, maxValueCategories)"
														:width="progressBarWidth"
														theme="transparent"
													)
													| {{ category.value }}

				li.card-item
					ContainerForData.card(
						width="100%"
						:isLoading="isLoading"
					)
						template(#header-left)
							span.card-title Brands
						template(#data)
							.content
								table
									tbody
										tr(v-for="brand in resp.productNotRankedInTop50ByBrand" :key="brand.brandId")
											td {{ brand.brandName }}
											td
												.py-12.flex.items-center
													ProgressBarGraphic.mr-auto(
														:progress-percent="getPercent(brand.value, maxValueBrands)"
														:width="progressBarWidth"
														theme="transparent"
													)
													| {{ brand.value }}

			p.no-data(v-else) Data not found. Please, change your filter params.

	.empty-filter(v-else) Пожалуйста, выберите значения фильтра
</template>

<script>
import Skeleton from '@/components/Nestle/Skeleton/Common.vue';
import ContainerForData from "@/components/Nestle/ContainerForData";
import ProgressBarGraphic from '@/components/Chart/ProgressBarGraphic.vue';

export default {
	name: "ShareOfTaxonomy",
	components: {
		Skeleton,
		ContainerForData,
		ProgressBarGraphic
	},
	props: {
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		date: {
			type: Object,
			default: () => {},
		},
		isVisibleData: {
			type: Boolean,
			default: false,
		},
		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
		regions: {
			type: String,
			default: '',
		},
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			resp: null,
			current: 1,
			limit: 20,
			offset: 0,
			isLoading: false,

			progressBarWidth: '207px',
		}
	},
	computed: {
		maxValueStores() {
			return this.getMaxValue('productNotRankedInTop50ByOnlineStore');
		},

		maxValueCategories() {
			return this.getMaxValue('productNotRankedInTop50ByCategory');
		},

		maxValueBrands() {
			return this.getMaxValue('productNotRankedInTop50ByBrand');
		},
	},
	methods: {
		getMaxValue(key) {
			if (!this.resp || !this.resp[key] || !Array.isArray(this.resp[key])) return 0;
			
			const maxValueItem = this.resp[key].reduce((acc, item) => {
				return acc.value >= item.value ? acc : item;
			})

			return maxValueItem.value;
		},

		getPercent(value, maxValue) {
			const percent = maxValue / 100;
			return Math.floor(value / percent);
		},

		async fetch() {
			this.$emit('showData')
			const params = {
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
				regions: this.regions,
				category: this.group,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				offset: this.offset,
				limit: this.limit,
			};
			try {
				this.isLoading = true
				this.resp = await this.$api.nestle.getProductFails(params)
			} catch {
				this.resp = null;
			} finally {
				this.isLoading = false
			}
		},
	},
	watch: {
		needUpdateResult: {
			immediate: true,
			async handler() {
				this.offset = 0
				this.current = 1
				await this.fetch()
			}
		},
		current: {
			handler() {
				this.offset = this.limit * (this.current-1)
				this.fetch()
			},
		},
	},
}
</script>

<style lang="scss" scoped>
.tab-title {
	font-family: 'OpenSans';
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	color: #000000;
}

.card-list {
	gap: 24px;
}

.card-item {
	flex: 1 1 411px;
}

.card {
	min-height: 100%;
}

.card-title {
	font-family: 'OpenSans';
	font-size: 14px;
	font-weight: 600;
	line-height: 16px;
	color: #757575;
}

.content {
	min-height: 416px;
	width: 100%;
}

table {
	width: 100%;
	font-family: 'OpenSans';
	font-size: 10px;
	font-weight: 400;
	line-height: 16px;
	color: #A2AEBE;
}

td:first-of-type {
	padding-right: 12px;
}

td:last-of-type {
	width: 241px;
	border-bottom: 1px solid #F5F5F5;
}

tr:first-of-type td:last-of-type {
	border-top: 1px solid #F5F5F5;
}

.no-data {
	margin-top: 20px;
}

.empty-filter {
	font-size: 16px;
	display: flex;
	justify-content: center;
	margin-top: 30px;
}
</style>	
